/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Text, Button, Divider } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Reference"}>
        <SiteHeader />

        <Column className="--center pb--30 pt--30" name={"uvod"} style={{"backgroundColor":"rgba(0,92,183,1)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" anim={null} style={{"maxWidth":""}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s4 pb--0 pl--0 pr--0 pt--0" anim={"2"} animS={"4"} style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--invert" style={{"maxWidth":""}} content={"Reference"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--0 pt--20" name={"zlutypruh"} style={{"backgroundColor":"#ffd500"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="--l8w pt--60" name={"portfolio"} style={{"paddingBottom":56,"backgroundColor":"rgba(0,92,183,1)"}} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Image className="--shape4" alt={"Reference"} src={"https://cdn.swbpg.com/t/17432/a7fbf96fd8a4422dbf077a9fed10fcdc_e=14x0x600x600_s=350x_.jpg"} sizes={"100vw"} style={{"border":"3px solid white","maxWidth":"","boxSizing":"border-box"}} srcSet={"https://cdn.swbpg.com/t/17432/a7fbf96fd8a4422dbf077a9fed10fcdc_e=14x0x600x600_s=350x_.jpg 350w"} position={null}>
              </Image>

              <Text className="text-box" style={{"marginTop":11}} content={"<span style=\"color: rgb(255, 255, 255);\">Reference</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Image className="--shape4" alt={"Reference"} src={"https://cdn.swbpg.com/t/17432/aa5c4987419c417cac33345308725b6a_e=6x235x448x448_s=350x_.png"} sizes={"100vw"} style={{"border":"3px solid white","maxWidth":"","boxSizing":"border-box"}} srcSet={"https://cdn.swbpg.com/t/17432/aa5c4987419c417cac33345308725b6a_e=6x235x448x448_s=350x_.png 350w"} position={null}>
              </Image>

              <Text className="text-box" style={{"marginTop":11}} content={"<span style=\"color: rgb(255, 255, 255);\">Reference</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Image className="--shape4" alt={"Reference"} src={"https://cdn.swbpg.com/t/17432/d44492d16df942b09dfd99d94ad9a9de_e=240x47x666x672_s=660x_.jpg"} sizes={"100vw"} style={{"border":"3px solid white","maxWidth":"","boxSizing":"border-box"}} srcSet={"https://cdn.swbpg.com/t/17432/d44492d16df942b09dfd99d94ad9a9de_e=240x47x666x672_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17432/d44492d16df942b09dfd99d94ad9a9de_e=240x47x666x672_s=660x_.jpg 660w"} position={null}>
              </Image>

              <Text className="text-box" style={{"marginTop":11}} content={"<span style=\"color: rgb(255, 255, 255);\">Reference</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Image className="--shape4" alt={"Reference"} src={"https://cdn.swbpg.com/t/17432/b5876d9c9b134f5fb448d3f1ce173499_e=0x112x900x450_s=660x_.jpg"} sizes={"100vw"} style={{"border":"3px solid white","maxWidth":"","boxSizing":"border-box"}} srcSet={"https://cdn.swbpg.com/t/17432/b5876d9c9b134f5fb448d3f1ce173499_e=0x112x900x450_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17432/b5876d9c9b134f5fb448d3f1ce173499_e=0x112x900x450_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17432/b5876d9c9b134f5fb448d3f1ce173499_e=0x112x900x450_s=860x_.jpg 860w"} position={null}>
              </Image>

              <Text className="text-box" style={{"marginTop":11}} content={"<span style=\"color: rgb(255, 255, 255);\">Reference</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Image className="--shape4" alt={"Reference"} src={"https://cdn.swbpg.com/t/17432/bece59a9d8d24793ad243b464fba376d_e=399x0x801x801_s=660x_.jpg"} sizes={"100vw"} style={{"border":"3px solid white","maxWidth":"","boxSizing":"border-box"}} srcSet={"https://cdn.swbpg.com/t/17432/bece59a9d8d24793ad243b464fba376d_e=399x0x801x801_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17432/bece59a9d8d24793ad243b464fba376d_e=399x0x801x801_s=660x_.jpg 660w"} position={null}>
              </Image>

              <Text className="text-box" style={{"marginTop":11}} content={"<span style=\"color: rgb(255, 255, 255);\">Reference</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Image className="--shape4" alt={"Reference"} src={"https://cdn.swbpg.com/t/17432/bece59a9d8d24793ad243b464fba376d_e=399x0x801x801_s=660x_.jpg"} sizes={"100vw"} style={{"border":"3px solid white","maxWidth":"","boxSizing":"border-box"}} srcSet={"https://cdn.swbpg.com/t/17432/bece59a9d8d24793ad243b464fba376d_e=399x0x801x801_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17432/bece59a9d8d24793ad243b464fba376d_e=399x0x801x801_s=660x_.jpg 660w"} position={null}>
              </Image>

              <Text className="text-box" style={{"marginTop":11}} content={"<span style=\"color: rgb(255, 255, 255);\">Reference</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Image className="--shape4" alt={"Reference"} src={"https://cdn.swbpg.com/t/17432/bece59a9d8d24793ad243b464fba376d_e=399x0x801x801_s=660x_.jpg"} sizes={"100vw"} style={{"border":"3px solid white","maxWidth":"","boxSizing":"border-box"}} srcSet={"https://cdn.swbpg.com/t/17432/bece59a9d8d24793ad243b464fba376d_e=399x0x801x801_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17432/bece59a9d8d24793ad243b464fba376d_e=399x0x801x801_s=660x_.jpg 660w"} position={null}>
              </Image>

              <Text className="text-box" style={{"marginTop":11}} content={"<span style=\"color: rgb(255, 255, 255);\">Reference</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" name={"kontakt"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(0,92,183,1)"}}>
          
          <ColumnWrap className="column__flex --shape5 --center el--1 pb--25 pt--25 flex--center" style={{"marginTop":0,"backgroundColor":"rgba(0,0,0,0)"}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2" anim={"2"} style={{"maxWidth":595}}>
              
              <Title className="title-box fs--43" content={"<span style=\"color: var(--white);\">Ozvěte se nám</span>"}>
              </Title>

              <Button className="btn-box btn-box--pbtn3 btn-box--shape4 fs--18 mt--16" use={"page"} href={"/kontakt"} content={"Kontaktovat"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" name={"paticka"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Divider className="mt--20">
              </Divider>

              <Title className="title-box title-box--center fs--36" content={"<span style=\"font-weight: bold;\"><br>Kontakt:</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Text className="text-box text-box--center fs--20" content={"<span style=\"color: var(--color-supplementary);\"><span style=\"font-weight: bold;\">+420</span> <span style=\"font-weight: bold;\">734 148 215</span></span><br>"}>
              </Text>

              <Text className="text-box text-box--center fs--20" style={{"marginTop":0}} content={"<a href=\"mailto:uklidnaklik@gmail.com?subject=Popt%C3%A1vka+z+webu\" style=\"font-weight: bold; color: var(--color-supplementary);\">uklidnaklik@gmail.com</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}